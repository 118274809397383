<template>
  <div class="header bg-brand">
    <nav class="navbar navbar-dark" id="navbar">
      <div class="container-fluid">
        <router-link class="navbar-brand mx-auto" to="/">
          <img class="logo" src="/images/placeholder-block.png">
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  data() {
    return {
      mobileMenu: false,
      classMenu : false
    }
  },
  computed : {
    routeName (){
      return this.$route.name;
    }
  },
  methods: {
    changeMenu(menu) {
      this.$store.commit('changeMenu', menu);
      this.mobileMenu = false;
    },
    toggleMenu() {
      this.mobileMenu ? this.mobileMenu = false : this.mobileMenu = true;
    }
  }
}
</script>
<style scoped>
.header{
  padding: 5px 0 5px 0;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 10000;
  border-bottom: 1px solid #fff;
}

.navbar{
  background-color: transparent;
}

.logo{
  width: 180px;
}

/*menu drawer*/

.drawer {
  font-family: 'Space Mono', monospace !important;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background-color: rgba(0,0,0,0.95);
  overflow: auto;
}

.drawer.is-open, .drawer-overlay.is-open{
  transition: 0.3s;
  display: block;
}

.drawer-menu{
  list-style: none;
  padding: 0;
  margin: 0;
}

.drawer-menu .menu-item {
  text-align: center;
}

.drawer-menu .menu-item:hover{
  color: #dedede;
}

.drawer-menu .menu-item a {
  font-size: 20px;
  display: block;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  margin: 30px 0;
}

ul {
  padding: 0;
  margin: 0;
}
.drawer .close{
  padding: 20px;
  text-align: center;
}

.drawer .button-exit{
  background-color: #000;
  color: #ffffff;
  border: none;
}

.button-menu{
  width: 35px;
  height: 35px;
  border: none;
  margin-right: 10px;
  background-color: transparent;
}

.button-menu svg{
  fill: #fff;
}

.drawer .button-exit{
  width: 35px;
  height: 35px;
  border: none;
  margin-right: 10px;
  background-color: transparent;
}

.drawer .button-exit svg{
  fill: #fff;
}

.navbar-bg {
  background-color: #000;
}
</style>
