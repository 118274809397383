<template>
  <Spinner/>
  <Header/>
  <router-view :key="$route.path"/>
  <Footer/>
  <ScrollTop/>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Spinner from '@/components/Spinner'
import ScrollTop from "@/components/ScrollTop";

export default {
  name: 'App',
  components: {
    ScrollTop,
    Spinner,
    Header,
    Footer
  }
}
</script>

<style>

</style>
