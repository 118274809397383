<template>
  <div class="wrappage">
    <section class="wraphome bg-brand pt-5 pb-5">
      <div class="container text-center">
        <h4 class="text-orange fw-bolder">12-13 APRILE 2024</h4>
        <h1 class="jumbotron-heading fw-bolder text-white">PARCO DELL'INNOVAZIONE</h1>
        <p class="lead text-white">
          L'AQUILA CITTA' DELLA CONOSCENZA E DELLA RICERCA.<br>
          VERSO LA AUGMENTED CITY
        </p>
        <p>
          <a href="#" class="btn btn-brand my-2 mt-4">SCOPRI DI PIÚ</a>
        </p>
        <div class="scroll-icon mt-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM127 281c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l71 71L232 136c0-13.3 10.7-24 24-24s24 10.7 24 24l0 182.1 71-71c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 393c-9.4 9.4-24.6 9.4-33.9 0L127 281z"/>
          </svg>
        </div>
      </div>
    </section>
    <section class="wraphome border-bottom-purple">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 border-right-purple p-5">
            <h2 class="text-violet fw-bold">UN EVENTO PER DISSEMINARE<br>E TRASFERIRE INNOVAZIONE A L'AQUILA</h2>
            <p class="text-violet fw-lighter">
              Parco dell'Innovazione nasce per valorizzare il <b>territorio</b> e il suo <b>ecosistema di innovazione</b>,
              creando connessioni virtuose tra <b>persone</b>, <b>spazi</b> e <b>tecnologie</b> all'interno del contesto urbano.
              Attraverso eventi, workshop, laboratori e sessioni di confronto tra stakeholder del territorio
              attiveremo un percorso evolutivo che in tre anni renderà L’Aquila la <b>prima città aumentata</b> nel contesto nazionale.
            </p>
          </div>
          <div class="col-lg-5 p-5">
            <div class="row p-3">
              <div class="col-6 mb-4">
                <div class="d-flex">
                  <div class="flex-shrink-0 icon">
                    <img class="bp-icon" src="/images/icons/bp-icon.png">
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-icons text-violet">
                      Eventi
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="d-flex">
                  <div class="flex-shrink-0 icon">
                    <div class="flex-shrink-0 icon">
                      <img class="bp-icon" src="/images/icons/bp-icon.png">
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-icons text-violet">
                      Workshop
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-3">
              <div class="col-6 mb-4">
                <div class="d-flex">
                  <div class="flex-shrink-0 icon">
                    <div class="flex-shrink-0 icon">
                      <img class="bp-icon" src="/images/icons/bp-icon.png">
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-icons text-violet">
                      Laboratori<br>per bambini<br>e famiglie
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="d-flex">
                  <div class="flex-shrink-0 icon">
                    <div class="flex-shrink-0 icon">
                      <img class="bp-icon" src="/images/icons/bp-icon.png">
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-icons text-violet">
                      Visite guidate
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-3">
              <div class="col-6 mb-4">
                <div class="d-flex">
                  <div class="flex-shrink-0 icon">
                    <div class="flex-shrink-0 icon">
                      <img class="bp-icon" src="/images/icons/bp-icon.png">
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-icons text-violet">
                      Area Expo
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="d-flex">
                  <div class="flex-shrink-0 icon">
                    <div class="flex-shrink-0 icon">
                      <img class="bp-icon" src="/images/icons/bp-icon.png">
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-icons text-violet">
                      Area food<br>& beverage
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="wraphome pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="text-center fw-bold text-violet">I MAIN EVENTS</h2>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-3">
            <div class="d-flex">
              <div class="flex-shrink-0 icon">
                <img class="bp-icon" src="/images/icons/tedx-icon.png">
              </div>
              <div class="flex-grow-1 ms-3">
                <h5 class="text-violet title-icons fw-bold">TEDx L'Aquila</h5>
                <p class="text-icons text-violet">
                  Speech ispirazionali tenuti dai migliori tough leader italiani per diffondere idee che cambiano il mondo.
                  <br>
                  <img class="img-fluid mt-3" src="/images/placeholder-block.png">
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="d-flex">
              <div class="flex-shrink-0 icon">
                <img class="bp-icon" src="/images/icons/hackin-icon.png">
              </div>
              <div class="flex-grow-1 ms-3">
                <h5 class="text-violet title-icons fw-bold">HACKin L'Aquila</h5>
                <p class="text-icons text-violet">
                  Workshop di co-design per identificare i perimetri di innovazione all'Aquila.
                  <br>
                  <img class="img-fluid mt-3" src="/images/placeholder-block.png">
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="d-flex">
              <div class="flex-shrink-0 icon">
                <img class="bp-icon" src="/images/icons/c-icon.png">
              </div>
              <div class="flex-grow-1 ms-3">
                <h5 class="text-violet title-icons fw-bold">Cultural Hub</h5>
                <p class="text-icons text-violet">
                  Un polo per stimolare la creatività attraverso la (ri)scoperta dell’arte nelle LSEP sue declinazioni.
                  <br>
                  <img class="img-fluid mt-3" src="/images/placeholder-block.png">
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="d-flex">
              <div class="flex-shrink-0 icon">
                <img class="bp-icon" src="/images/icons/stexpo-icon.png">
              </div>
              <div class="flex-grow-1 ms-3">
                <h5 class="text-violet title-icons fw-bold">Startup Expo</h5>
                <p class="text-icons text-violet">
                  Un’area espositiva in cui dare spazio alle startup locali e agli attori dell’ecosistema.
                  <br>
                  <img class="img-fluid mt-3" src="/images/placeholder-block.png">
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 text-center">
            <button class="btn btn-outline-secondary p-3">SCARICA IL PROGRAMMA IN ANTEPRIMA!</button>
          </div>
        </div>
      </div>
    </section>
    <section class="wraphome pt-5 pb-5">
      <div class="row">
        <div class="col-lg-12 text-center">
          <GoogleMap/>
        </div>
      </div>
    </section>
    <section class="wraphome pt-2 pb-5 border-bottom-purple">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="text-violet fw-bold">PERCHÉ PARTECIPARE</h2>
            <p class="text-grey">
              Per promuovere un apprendimento inclusivo e collaborativo che<br>coinvolga la community e le famiglie
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="wraphome">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4 text-center bg-brand colour-block">
            <h2 class="text-white fw-bold">PER GENERARE UN IMPATTO</h2>
            <p class="text-white fs-5 text-block">
              Alimenta lo scambio di conoscenze, assorbi da ciò che ti sta intorno e diffondi le tue idee:
              qui disseminiamo innovazione che fa bene al territorio
            </p>
          </div>
          <div class="col-lg-4 text-center bg-purple colour-block">
            <h2 class="text-white fw-bold">PER AVERE VOCE IN CAPITOLO</h2>
            <p class="text-white fs-5 text-block">
              Cogli l'opportunità di dire la tua su uno dei tanti palcoscenici attivi all'interno del Parco.
              Valore per chi ti ascolta, valore per il tuo brand.
            </p>
          </div>
          <div class="col-lg-4 text-center bg-blue colour-block">
            <h2 class="text-white fw-bold">PER POSIZIONARTI NELL'ECOSISTEMA</h2>
            <p class="text-white fs-5 text-block">
              Istituzioni, università,
              corporate, startup e innovatori: tutti all'interno dello stesso Parco. Se ti occupi di innovazione,
              non puoi non esserci.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="wraphome pt-5 pb-5 border-bottom-purple">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h2 class="text-violet fw-bold text-center">VUOI CONTRIBUIRE ALL'EVOLUZIONE DELL'AQUILA?</h2>
          </div>
          <div class="col-lg-4 text-center">
            <button class="btn btn-brand mt-4">DIVENTA PARTNER</button>
          </div>
          <div class="col-lg-4 text-center">
            <button class="btn btn-brand mt-4">CANDIDATI COME VOLONTARIO</button>
          </div>
        </div>
      </div>
    </section>
    <section class="wraphome pb-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 bg-grey"></div>
          <div class="col-lg-6 bg-brand block-padding">
            <h5 class="text-white pt-5">IL PROGETTO</h5>
            <h2 class="text-white fw-bold mb-4">
              DA LEARNING<br>AD AUGMENTED CITY
            </h2>
            <p class="text-white fw-lighter pb-5">
              Con 3 edizioni in 3 anni,
              il Parco dell'Innovazione mira a identificare e connettere persone
              e realtà innovative che supporteranno LSEP il percorso evolutivo dell’Aquila
              da città della conoscenza a città aumentata e punto di riferimento per l’ecosistema
              innovazione in Italia.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="wraphome pt-1 pb-5 border-bottom-purple">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="text-violet fw-bolder">PARTNER E SPONSOR</h2>
          </div>
        </div>
      </div>
    </section>
    <section class="wraphome pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 text-center mb-5">
            <img class="img-fluid" src="/images/partners/files/saturno.png">
          </div>
          <div class="col-lg-3 text-center mb-5">
            <img class="img-fluid" src="/images/partners/files/startupaq.png">
          </div>
          <div class="col-lg-3 text-center mb-5">
            <img class="img-fluid" src="/images/partners/files/seedble.png">
          </div>
          <div class="col-lg-3 text-center mb-5">
            <img class="img-fluid" src="/images/partners/files/cteaq.png">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 text-center mb-5">
            <img class="img-fluid" src="/images/partners/files/blendx.png">
          </div>
          <div class="col-lg-3 text-center mb-5">
            <img class="img-fluid" src="/images/partners/files/arch2.png">
          </div>
          <div class="col-lg-3 text-center mb-5">
            <img class="img-fluid" src="/images/partners/files/starcup.png">
          </div>
          <div class="col-lg-3 text-center mb-5">
            <img class="img-fluid" src="/images/partners/files/ydea.png">
          </div>
        </div>
      </div>
    </section>
    <section class="wraphome bg-orange pb-2">
      <div class="container-fluid">
        <div class="row border-bottom-white">
          <div class="col-lg-4 p-5 border-right-white">
            <h5 class="text-white fw-bolder">SEEDBLE SRL</h5>
            <p class="text-white fw-lighter fs-6">
              Seedble è un facilitatore di innovazione che da 10 anni lavora con aziende e startup per guidarle in
              percorsi di crescita, contaminazione ed evoluzione attraverso il disegno e l’implementazione di
              progetti di corporate innovation e accelerazione di business
            </p>
            <p class="text-purple fs-6 fw-bolder">
              <b>#INNOVATION #SOCIALIMPACT #OPENINNOVATION #DIGITALTRASFORMATION</b>
            </p>
          </div>
          <div class="col-lg-4 p-5 border-left-white">
            <h5 class="text-white fw-bolder">CTE L'AQUILA</h5>
            <p class="text-white fw-lighter fs-6">
              ”Sicura” è la Casa delle Tecnologie Emergenti (CTE) de L’Aquila, il primo living lab permanente che si
              candida a diventare l’officina delle idee della futura L’Aquila Smart City.
            </p>
            <p class="text-purple fs-6 fw-bolder">
              <b>#INNOVATION #SOCIALIMPACT #OPENINNOVATION #DIGITALTRASFORMATION</b>
            </p>
          </div>
          <div class="col-lg-4 p-5">
            <h5 class="text-white fw-bolder">SATURNO CONSULTING SRL</h5>
            <p class="text-white fw-lighter fs-6">
              Digital Consulting italiana verticalizzata sul mondo delle "omnichannel companies". Forniamo soluzioni
              software proprietarie professionali di altissimo livello e consulenza per gestire e far crescere brand di
              nuova generazione.
            </p>
            <p class="text-purple fs-6 fw-bolder">
              <b>#INNOVATION #SOCIALIMPACT #OPENINNOVATION #DIGITALTRASFORMATION</b>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import GoogleMap from '@/components/GoogleMap.vue';
export default {
  name: 'Home',
  components : {
    GoogleMap
  }
}
</script>

<style scoped>

.block-padding{
  padding: 100px 70px 100px 70px;
}

@media (max-width: 576px) {
  .p-5{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .border-right-purple{
    border: 0 !important;
  }

  .wraphome h1{
    font-size: 38px;
  }

  .wraphome p{
    font-size: 25px;
  }

  .block-padding{
    padding: 100px 15px 100px 15px;
  }
}

@media (max-width: 768px) {

}

@media (max-width: 992px) {

}

@media (max-width: 1200px) {

}
</style>
