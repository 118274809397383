import { createApp } from 'vue';
import App from './App.vue'
import store from "@/store";
import router from "@/router";
import './interceptors/axios';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
