import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta : {
            title : "Parco dell'Innovazione",
            description : "Portale del Parco dell'Innovazione"
        }
    }
];

const router = new createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to,from, savedPosition) {
        if(savedPosition){
            return savedPosition
        }
    }
});

router.beforeEach((to) => {
    document.title = to.meta.title;
    //document.querySelector('meta[name="description"]').setAttribute("content", to.meta.description);
});

export default router;