<template>
  <GoogleMap
      :api-key="key"
      style="width: 100%; height: 500px"
      :center="center"
      :zoom="15"
  />
</template>

<script>
import {GoogleMap} from 'vue3-google-map';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  data() {
    return {
      key : "AIzaSyCz_pTm2aWy45yAPuJpnHBwgNCg2XrEQhQ",
      center : { lat: 42.35113532671698, lng: 13.399570433176072 }
    }
  },
  components : {
    GoogleMap
  },
  computed : {},
  methods: {

  }
}
</script>

<style scoped>

</style>
